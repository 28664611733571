.swipe {
  width: 100%;
  height: 70vh;
  position: fixed;
  top: 70px;
  overflow-x: none;
  overflow-y: hidden;
}
.container {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 4%;
}
.cross {
  position: absolute;
  font-size: 20px;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 100px;
  row-gap: 30px;
}
@media screen and (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    /* column-gap: 100px;
    row-gap: 30px; */
  }
  .swipe {
    width: 130%;
    height: 65vh;
    position: fixed;
    top: 70px;
    overflow-x: none;
    overflow-y: hidden;
  }
}
.small-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
}
.title {
  font-weight: 500;
  text-align: left;
}
.overflow {
  height: 57vh;
  overflow-y: auto;
}
::-webkit-scrollbar {
  display: none;
}
/* new one */
.align-left {
  text-align: left;
  color: #808080;
}
